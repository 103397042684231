@import '../../styles/global.scss';

.content-block{
    &__section-wrapper {
      display: flex;
      flex-direction: column-reverse;
      gap: $spacing-16;
      padding: $spacing-16 $spacing-32;

      @include tablet {
        padding: $spacing-24 $spacing-80;
      }

      @include desktop {
        padding: $spacing-32 $spacing-120;
        max-width: $desktop-total-width;
        margin: auto;
      }
    }

    &__content{
        white-space: pre-line;
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            gap: $spacing-8;
        }

        @include tablet{
            gap: $spacing-8;
        }
    }

    &__header{
        @include h3;
    }

    &__subheader{
        @include body-big;
        margin: $spacing-8 0;

        @include desktop{
            margin-top: $spacing-16;
            margin-bottom: $spacing-8;
        }

        @include tablet{
            margin-top: $spacing-16;
            margin-bottom: $spacing-8;
        }
    }

    &__body{
        text-align: left;
    }

    &__custom-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-top: $spacing-8;
      }

      &__custom-list-item {
        display: flex;
        align-items: flex-start;
        font-size: 1rem;
        gap: 0.5rem;
        @include body-small;
      
        i {
            font-size: 1.2rem;
          }
      
          .icon-green {
            color: $icon-green;
          }
      
          .icon-red {
            color: $icon-red;
          }
      }

      &__image{
        @include border-radius;
        width: 100%;
      }

      &__custom-text{
        line-height: to-rem(20);
      }

      &__video {
        width: 100%;
        border-radius: to-rem(8);
        margin: auto;
      
        @include desktop {
          max-width: 100%;
          border-radius: to-rem(16);
        }
      
        @include tablet {
          max-width: 100%;
          border-radius: to-rem(14);
        }
      }
}