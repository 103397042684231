@import '../../styles/global.scss';

.button {
  padding: 0 to-rem(24);
  border-radius: to-rem(8);
  justify-content: center;
  text-align: center;
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  height: to-rem(44);  

  @include tablet {
    height: to-rem(44); 
    width: fit-content;
    display: inline-flex;
  }

  @include desktop {
    height: to-rem(40);  
    width: fit-content;
    display: inline-flex;
  }

  &--primary {
    background: $button-default;
    color: $text-invert;
    border: none;

    &:hover {
      background: $button-hover;
    }

    &:active {
      background: $button-active;
    }
  }

  &--secondary {
    border: 1.5px solid $button-default;
    color: $button-default;

    &:hover {
      border-color: $button-hover;
      color: $button-hover;
    }

    &:active {
      border-color: $button-active;
      color: $button-active;
    }
  }

  &--ghost {
    background: rgba(0, 0, 0, 0.10);
    text-decoration: none;
    color: $text;

    &:hover {
      background: rgba(0, 0, 0, 0.20);
    }

    &:active {
      background: rgba(0, 0, 0, 0.30);
    }
  }

  &__full-width {
    width: 100%;
  }

  &__text {
    font-weight: 600;
    @include body-small;
    white-space: nowrap;
    text-decoration: none;
  }
}